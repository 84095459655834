<template>
  <div>
    <tab-bar-wrapper @newRow="newBuildUp()">
      <template #parent-buttons>
          <v-switch
            v-model="hideCompleted"
            small
            hide-details
            color="blue"
            label="Hide Completed"
            class="mt-0"
          ></v-switch>
      </template>
    </tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :componentName="$options.name"
        :annotations="annotations"
        :query="query"
        :filter="filters"
        :columns="columns"
        :nested-query="nestedQuery"
        :nested-filters="nestedFilters"
        :nested-annotations="nestedAnnotations"
        :context-menu-items="contextMenuItems"
        :nested-grid="true"
        :nested-columns="nestedColumns"
        :nested-context-menu-items="nestedContextMenuItems"
        @ready="gridReady"
        @nestedReady="nestedGridReady"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <v-dialog
      v-model="localDialog"
      lazy
      persistent
      width="400px"
    >
      <v-card>
         <v-card-title class="blue lighten-2">
          <h3 class="white--text">Assign Build Up Task To:</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row justify-center align-center>
              <v-flex shrink>
                <users-list
                  :userId="assigneeID"
                  @updated="usersEventHandler"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <cancel-button @click="localDialog = false"/>
          <submit-button :disabled="!assigneeID" :loading="assigning" @click="assignBuildUp"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tabBarWrapper from '@/components/wrappers/tabBarWrapper'
import gridWrapper from '@/components/wrappers/gridWrapper'
import grid from '@/components/mixins/grid'
import columns from '@/components/mixins/columns'
import {
  GET_BUILD_UP_ITEMS__GRID,
  GET_BUILD_UPS__GRID,
  UPDATE_BUILDUP, UPDATE_BUILDUP_ITEMS
} from '@/api/graphql/Constants/Assembly'
import users from '@/components/autocompletes/users'
import { mapActions } from 'vuex'
import { SET_SYSTEM_ADD_ON_ITEMS } from '@/api/graphql/Constants/Inventory'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__BUILD_UP, KB_SHORTCUT__BUILD_UP_NESTED } from '@/lib/agGridKbShortCuts'
import { CONTEXT_MENU__BUILD_UP, CONTEXT_MENU__BUILD_UP_NESTED } from '@/lib/agGridContextMenuConfigurations'
import { COLUMNS__BUILD_UP, COLUMNS__BUILD_UP_NESTED } from '@/lib/agGridColumnConfiguration'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'BuildUp',
  mixins: [grid, columns],
  components: {
    'tab-bar-wrapper': tabBarWrapper,
    'grid-wrapper': gridWrapper,
    'users-list': users,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  watch: {
    hideCompleted: function (value) {
      if (value) {
        this.filters.push({
          key: 'status__id__lt',
          value: 78
        })
      } else {
        this.filters.splice(0, 1)
      }
    }
  },
  data () {
    return {
      contextMenuItems: params => CONTEXT_MENU__BUILD_UP(params, this.contextMenuCallback),

      nestedContextMenuItems: params => CONTEXT_MENU__BUILD_UP_NESTED(params, this.contextMenuCallback),

      gridApi: null,
      columnApi: null,
      nestedGridApi: null,
      nestedColumnApi: null,
      nestedRowData: [],

      query: GET_BUILD_UPS__GRID,
      filters: [{
        key: 'status__id__lt',
        value: 78
      }],
      annotations: [
        {
          name: 'nonCompleteItems',
          aggr_type: 'SUM',
          aggr_field: 'bu_items',
          aggr_filters: [{ key: 'bu_items__status__id__lt', value: 83 }]
        },
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'bu_items'
        }
      ],

      nestedQuery: GET_BUILD_UP_ITEMS__GRID,
      nestedFilters: (id) => [{ key: 'build_up__id', value: id }],
      nestedAnnotations: [],

      hideCompleted: true,

      columns: COLUMNS__BUILD_UP,
      nestedColumns: COLUMNS__BUILD_UP_NESTED,

      localDialog: false,
      taskIdToAssign: 0,
      assigneeID: 0,
      assigning: false

    }
  },
  methods: {
    ...mapActions('notifications', {
      createSnackbar: 'createSnackbar'
    }),

    rowMasterFunction (data) {
      return RowMasterFunctionForItemCountAnnotation(data.annotations)
    },

    contextMenuCallback (params) {
      if (params.data && params.dataKey) this[params.dataKey] = params.data
      if (params.dialogToOpen) this.localDialog = params.dialogToOpen
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__BUILD_UP(params, this.contextMenuCallback), KB_SHORTCUT__BUILD_UP_NESTED(params, this.contextMenuCallback))
    },

    gridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi

      const sort = [{ colId: 'id', sort: 'desc' }]
      this.gridApi.setSortModel(sort)
    },

    nestedGridReady (params) {
      this.nestedGridApi = params.api
      this.nestedColumnApi = params.columnApi
    },

    goToSystemOfBuildUp ({ params }) {
      this.$router.push({ name: 'system', query: { key: 'id', value: params.node.data.system.id } })
    },

    prepareBuildUpAssignment ({ params }) {
      this.taskIdToAssign = params.node.data.id
      this.assigneeID = params.node.data.assigned_to?.id ?? 0
      this.localDialog = true
    },

    assignBuildUp ({ user = this.assigneeID, buildUpID = this.taskIdToAssign }) {
      this.assigning = true
      const input = { id: buildUpID, assignee: user }
      this.$apollo.mutate({
        mutation: UPDATE_BUILDUP,
        variables: input
      }).then(() => {
        this.localDialog = false
        this.assigning = false
        this.createSnackbar({
          message: 'Assigned!',
          color: 'success',
          top: false,
          canClose: false,
          timeout: 2000
        })
      })
    },

    buildUpIsReady ({ params }) {
      this.createSnackbar({
        message: 'Marking the task as ToDo',
        color: 'info'
      })
      const input = { id: params.node.data.id, status_id: 75 }
      this.$apollo.mutate({
        mutation: UPDATE_BUILDUP,
        variables: input
      }).then(() => {
        this.createSnackbar({
          message: 'Grabbing build up item information',
          color: 'info'
        })
        this.$apollo.query({
          query: GET_BUILD_UP_ITEMS__GRID,
          variables: { input: { filters: [{ key: 'build_up__id', value: params.node.data.id }] } }
        }).then(({ data: { build_up_build_up_items } }) => {
          const buildUpItems = build_up_build_up_items
          const items = []
          for (const item of buildUpItems) {
            item.status.id !== 80 && items.push({ id: item.id, status_id: 80 })
          }
          try {
            this.$apollo.mutate({
              mutation: UPDATE_BUILDUP_ITEMS,
              variables: { input: items }
            })
            this.createSnackbar({ message: 'Updated!', color: 'success' })
          } catch (error) {
            this.createSnackbar({ message: error.message, color: 'success' })
          }
        })
      })
    },

    /**
     * Marks any selected build up items as the next status from which they are currently on i.e. status + 1
     * @param params
     * @param reset whether or not to reset them (for testing)
     */
    markBuildUpItem ({ params, back = false }) {
      const nodes = params.api.getSelectedNodes()
      const promises = []
      for (const node of nodes) {
        const nextStatus = back ? node.data.status.id - 1 : node.data.status.id + 1
        if ((!back && nextStatus <= 83) || (back && nextStatus >= 79)) {
          const promise = this.$apollo.mutate({
            mutation: UPDATE_BUILDUP_ITEMS,
            variables: { input: { id: node.data.id, status_id: nextStatus } }
          }).then(() => {
            return true
          }).catch(() => {
            return false
          })
          promises.push(promise)
        }
      }

      Promise.all(promises).then(values => {
        const atLeastOneFailed = values.some(v => v === false)
        const atLeastOneSucceeded = values.some(v => v === true)
        if (atLeastOneFailed && atLeastOneSucceeded) {
          this.createSnackbar({
            message: 'Succeeded with some errors (unchanged rows)',
            color: 'warning',
            timeout: 4000
          })
        } else if (atLeastOneFailed) {
          this.createSnackbar({
            message: 'An error has been captured and sent to support',
            color: 'error',
            timeout: 4000
          })
        } else if (atLeastOneSucceeded) {
          this.createSnackbar({
            message: 'Updated statuses!',
            color: 'success',
            timeout: 4000
          })
        }
        this.checkBuildUpStatusPostItemUpdate({ params: params })
      })
    },

    checkBuildUpStatusPostItemUpdate ({ params }) {
      const buildUpID = params.node.data.build_up.id
      this.$apollo.query({
        query: GET_BUILD_UP_ITEMS__GRID,
        variables: { input: { filters: [{ key: 'build_up__id', value: buildUpID }] } }
      }).then(({ data: { build_up_build_up_items } }) => {
        const buildUpItems = build_up_build_up_items
        const itemStatus = buildUpItems[0].status.id
        const allSame = buildUpItems.every(e => e.status.id === itemStatus)
        // if all the items are of the same status and that status doesn't mean that the build up task would get marked up as completed
        if (allSame && itemStatus - 5 !== 78) {
          const buildUpStatusAlignsWithItemStatuses = params.node.data.status.id === (buildUpItems[0].status.id - 5)
          !buildUpStatusAlignsWithItemStatuses && this.$apollo.mutate({
            mutation: UPDATE_BUILDUP,
            variables: { input: { id: buildUpID, status_id: (itemStatus - 5) } }
          })
        }
      })
    },

    completeBuildUp ({ params }) {
      this.createSnackbar({
        message: 'Marking Task as Complete',
        color: 'info'
      })
      const input = { id: params.node.data.id, status_id: 78 }
      this.$apollo.mutate({
        mutation: UPDATE_BUILDUP,
        variables: { input: input }
      }).then(({ data: { Update__BuildUp_BuildUp } }) => {
        const buildUp = Update__BuildUp_BuildUp
        this.createSnackbar({
          message: 'Complete, now grabbing items...',
          color: 'info'
        })
        this.$apollo.query({
          query: GET_BUILD_UP_ITEMS__GRID,
          variables: { input: { filters: [{ key: 'build_up__id', value: buildUp.id }] } }
        }).then(({ data: { build_up_build_up_items } }) => {
          const buildUpItems = build_up_build_up_items
          const promises = []
          for (const item of buildUpItems) {
            const promise = this.$apollo.mutate({
              mutation: UPDATE_BUILDUP_ITEMS,
              variables: { input: { id: item.id, status_id: 83 } }
            }).then(() => {
              return item.item.id // think I need this for add on items
            }).catch(() => {
              return false
            })
            promises.push(promise)
          }

          Promise.all(promises).then(values => {
            const ids = values.filter(v => v !== false)
            const someFailed = values.filter(v => v === false).length > 0

            if (ids.length > 0) {
              this.$apollo.mutate({
                mutation: SET_SYSTEM_ADD_ON_ITEMS,
                variables: { system: params.node.data.system.id, items: ids }
              }).then(() => {
                if (someFailed) {
                  this.createSnackbar({
                    message: 'Some items failed to update. Others have been added to the base system',
                    color: 'warning',
                    timeout: 5000
                  })
                } else {
                  this.createSnackbar({
                    message: 'Successfully built up system. Items will now appear under the base system',
                    color: 'success',
                    timeout: 5000
                  })
                }
              }).catch(() => {
                this.createSnackbar({
                  message: 'Could not add items to the base system. Error has been logged',
                  color: 'error',
                  timeout: 5000
                })
              })
            }
          })
        })
      })
    },

    newBuildUp () {
      this.$router.push({ name: 'new-build-up' })
    },

    /* Helper Methods */

    updateBuildUpItemStatusInGrid ({ buildUpItem }) {
      if (this.nestedGridApi) {
        const updatedBuildUpItem = this.nestedRowData.find(e => e.id === buildUpItem.id)
        updatedBuildUpItem.status = buildUpItem.status
        this.nestedGridApi.redrawRows([updatedBuildUpItem])
      }
    },

    usersEventHandler (event) {
      this.assigneeID = event.value
    }
  }
}
</script>
